import React, { useState, useEffect } from 'react';
import {
  Button,
} from 'react-bootstrap';
import { BiBookReader } from 'react-icons/bi';
import parse from 'html-react-parser';
import { CgClose } from 'react-icons/cg';

const SlideContent = ({
  slideText, slideImage,
}) => (
  <div className="d-flex slide">
    <div className="col-12 col-md-6 text d-flex align-items-center">
      {slideText ? <div>{parse(slideText)}</div> : ''}
    </div>
    <div className="col-12 col-md-6 d-flex align-items-center image">
      <img src={slideImage} alt="slide illustartion" />
    </div>
  </div>
);

export default SlideContent;
