import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import {
  Container, Row, Col, Carousel,
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { ThemeDispatchContext } from '../../context/ThemeContextProvider';

import SlideContent from './components/SlideContent/SlideContent';

import imageSlide01 from './images/Welcome-Slides-1.png';
import imageSlide02 from './images/Welcome-Slides-2.png';
import imageSlide03 from './images/Welcome-Slides-3.png';

import './wpIntroductionPost.scss';
import EbotiIntro from './components/EbotiIntro/EbotiIntro';

const wpIntroductionPost = ({
  data: {
    wpIntroductionPost: {
      title, content, acfIntroductionFields, seo, language, translations,
    },
  },
}) => {
  const [justifyContent, setJustifyContent] = useState('justify-content-center');
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const dispatch = useContext(GlobalDispatchContext);
  const dispatchTheme = useContext(ThemeDispatchContext);

  const [slide2animate, setSlide2animate] = useState(false);
  const [slide2Banimate, setSlide2Banimate] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);

  useEffect(() => {
    dispatch({ type: 'SET_TRANSLATION_LINKS', payload: { translationLinks: translations } });
    dispatch({ type: 'SET_LANG', payload: { lang: language } });
    dispatchTheme({ type: 'SET_THEME', payload: { themeName: 'biodiversity' } });

    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 37) {
        // Previous
        if (document.querySelector('.carousel-control-prev')) {
          document.querySelector('.carousel-control-prev').click();
        }
        return false;
      }
      if (e.keyCode === 39) {
        // Next
        if (document.querySelector('.carousel-control-next')) {
          document.querySelector('.carousel-control-next').click();
        }
        return false;
      }
      return false;
    });
    return () => window.removeEventListener('keydown', (e) => {
      if (e.keyCode === 37) {
        // Previous
        if (document.querySelector('.carousel-control-prev')) {
          document.querySelector('.carousel-control-prev').click();
        }
        return false;
      }
      if (e.keyCode === 39) {
        // Next
        if (document.querySelector('.carousel-control-next')) {
          document.querySelector('.carousel-control-next').click();
        }
        return false;
      }
      return false;
    });
  }, []);

  const handleSelect = (selectedIndex, e) => {
    console.log('selectedIndex', selectedIndex);
    console.log('is last', isLastSlide);

    setIndex(selectedIndex);
    setOpen(false);
    setIsLastSlide(false);
    if (selectedIndex === acfIntroductionFields.slidesContent.length - 1) {
      setTimeout(() => {
        setIsLastSlide(true);
        console.log('LAST SLIDE');
      }, 100);
    } else {
      setIsLastSlide(false);
    }
  };

  const handleOpenOrClosed = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleOnClick = () => {
    // Next
    if (document.querySelector('.toggle-nav-button')) {
      document.querySelector('.toggle-nav-button').click();
    }
  };

  return (
    <Layout>
      <SEO
        title={title}
        meta={
        [
          { opengraphSiteName: seo.opengraphSiteName },
          { opengraphType: seo.opengraphType },
          { opengraphTitle: seo.opengraphTitle },
          { opengraphImage: seo.opengraphImage?.link },
          { imageHeight: seo.opengraphImage?.mediaDetails.height },
          { imageWidth: seo.opengraphImage?.mediaDetails.width },
        ]
    }
      />
      <Container fluid className="container-global-styling introduction-slides">
        <Row className="h-100 justify-content-center align-items-center">
          <Container fluid className="h-100">
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              controls
              wrap={false}
              className={open ? 'modal-is-open' : ''}
            >
              <Carousel.Item>
                <div className="h-100 d-flex align-items-center">
                  <SlideContent
                    click={handleOpenOrClosed}
                    open={open}
                    slideText={acfIntroductionFields.slidesContent[0].text}
                    slideImage={imageSlide01}
                    activeSlideOrNot={index === 0}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="h-100 d-flex align-items-center">
                  <SlideContent
                    click={handleOpenOrClosed}
                    open={open}
                    slideText={acfIntroductionFields.slidesContent[1].text}
                    slideImage={imageSlide02}
                    activeSlideOrNot={index === 1}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="w-100 h-100 d-flex align-items-center">
                  <SlideContent
                    click={handleOpenOrClosed}
                    open={open}
                    slideText={acfIntroductionFields.slidesContent[2].text}
                    slideImage={imageSlide03}
                    activeSlideOrNot={index === 2}
                  />
                  <EbotiIntro />
                </div>
              </Carousel.Item>
            </Carousel>
            { (isLastSlide)
              ? (
                <button type="button" className="open-nav-btn position-absolute" onClick={handleOnClick}>
                  start
                </button>
              )
              : ''}
          </Container>
        </Row>
      </Container>
    </Layout>
  );
};
export default wpIntroductionPost;

export const query = graphql`
  query($id: String) {
    wpIntroductionPost(id: { eq: $id }) {
      id  
      title
      content
      translations {
        language {
          locale
          name
        }
        uri
      }
      language {
          locale
          name
      }
      acfIntroductionFields {
        fieldGroupName
        slidesContent {
        fieldGroupName
        text
        }
      }
      seo {
          opengraphUrl
          opengraphSiteName
          opengraphType
          opengraphTitle
          opengraphImage {
            link
            mediaDetails {
              height
              width
            }
          }
         }
    }
  }
`;
