import * as React from 'react';
import './eboti-intro.scss';

function EbotiIntro(props) {
  return (
    <svg
      className="eboti-intro"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path
            d="M144.05 68.16l-1 9.08s-12.83 2-42.14-1.38c-26.95-3.07-40.7-8-40.7-8l1-9.08c2.84-24.94 23.56-40.6 46.28-38 22.87 2.54 39.44 22.01 36.56 47.38z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-15,.prefix__cls-8{fill:none}.prefix__cls-3{fill:#2bb673}.prefix__cls-8{stroke-linecap:round;stroke-linejoin:round;stroke:#006838;stroke-width:4.23px}.prefix__cls-10{fill:#ecc577}.prefix__cls-11{fill:#dda862}.prefix__cls-15{stroke:#efd69e;stroke-miterlimit:10;stroke-width:3.52px}.prefix__cls-16{fill:#999;opacity:.5}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="prefix__Layer_1" data-name="Layer 1">
          <g id="prefix__Boti">
            <path
              className="prefix__cls-3"
              d="M118.12 141.27l4.17 16.78c2.44 9.8-3.26 19.8-12.73 22.33l-5 1.32A17.68 17.68 0 0183 168.52l-4.17-16.78c-2.44-9.8 3.26-19.8 12.73-22.32l5-1.33a17.68 17.68 0 0121.58 13.18"
            />
            <path
              d="M118.65 143.41l2.56 10.3A87.89 87.89 0 0181.89 164l-2.52-10.12c-2.44-9.81 3.26-19.8 12.74-22.33l5-1.32a17.66 17.66 0 0121.57 13.18"
              fill="#199b69"
            />
            <path
              className="prefix__cls-3"
              d="M25.81 111.82l7 8C66.12 158 123 160.91 159.9 126.41l7.71-7.22a4.32 4.32 0 00.29-6l-7-8C127.59 67.1 70.67 64.14 33.8 98.64l-7.7 7.22a4.3 4.3 0 00-.29 6"
            />
            <path
              d="M25.81 111.82l7 8C66.12 158 123 160.91 159.9 126.41l7.71-7.22a4.32 4.32 0 00.29-6l-7-8C127.59 67.1 70.67 64.14 33.8 98.64l-7.7 7.22a4.3 4.3 0 00-.29 5.96z"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#2bb673"
              strokeWidth={5.92}
              fill="none"
            />
            <path
              className="prefix__cls-3"
              d="M53 161.79a25.84 25.84 0 00-12.72-15.14 24.35 24.35 0 00-19.33-1.39 1.28 1.28 0 00-.78 1.62c4.43 13.7 18.8 21.12 32 16.53a1.3 1.3 0 00.79-1.62"
            />
            <path
              className="prefix__cls-3"
              d="M52.8 162.88a16.48 16.48 0 000-12.29 15.65 15.65 0 00-8.39-8.7.74.74 0 00-1 .42 16.28 16.28 0 008.37 21 .77.77 0 001-.43M158.21 173.79a26.17 26.17 0 00-9.91-17.23 24.49 24.49 0 00-18.78-4.94 1.27 1.27 0 00-1.05 1.45c2 14.3 14.87 24.25 28.69 22.17a1.29 1.29 0 001.05-1.45"
            />
            <path
              d="M156.42 114l-.86-1.11a82 82 0 00-26-22.32 71.24 71.24 0 00-61.44-3.19 81.13 81.13 0 00-28 19.51l-1 1a2.33 2.33 0 00-.14 2.93l.86 1.11a82 82 0 0026 22.32 71.24 71.24 0 0061.44 3.19 81.13 81.13 0 0028-19.51l1-1a2.28 2.28 0 00.59-1.44 2.22 2.22 0 00-.45-1.49"
              fill="#217b38"
            />
            <path
              d="M156.36 115.36l-.86-1.11a82.07 82.07 0 00-26-22.32 71.27 71.27 0 00-61.45-3.19 81.1 81.1 0 00-28 19.51l-1 1a2.31 2.31 0 00-.15 2.92l.86 1.11a82 82 0 0026 22.32 71.29 71.29 0 0061.44 3.2 81.13 81.13 0 0028-19.52l1-1a2.28 2.28 0 00.59-1.44 2.33 2.33 0 00-.45-1.49"
              fill="#beeda2"
            />
            <path
              className="prefix__cls-8"
              d="M87.69 123.31s-.6 10.1 8.18 10.56 9.06-9.66 9.06-9.66M132.97 119.07l-6.24-12.48-9.49 10.71M73.44 112.28L67.2 99.8l-9.5 10.71"
            />
            <g id="prefix__HAT">
              <rect
                x={100.85}
                y={18}
                width={12.36}
                height={7.28}
                rx={3.64}
                transform="rotate(6.49 106.95 21.628)"
                fill="#c18453"
              />
              <path
                id="prefix__form"
                className="prefix__cls-10"
                d="M187.82 95.18c-.93 3.31-3.93 5.89-7.18 5.85A923.44 923.44 0 0118.16 82.56c-3.18-.69-5.52-3.88-5.68-7.32a7.31 7.31 0 017.14-5.54c13.56-1.47 40.65-2.77 40.65-2.77l83 9.43s26 7.33 38.86 11.81a7.32 7.32 0 015.69 7.01z"
              />
              <path
                className="prefix__cls-11"
                d="M187.78 96.49c-.45 4-3.79 7.27-7.46 7.34A698.11 698.11 0 0117.84 85.36c-3.56-.9-6.07-4.87-5.62-8.83a6.7 6.7 0 01.26-1.29c.16 3.44 2.81 3.94 6 4.63A923.55 923.55 0 00181 98.34c3.25 0 5.94.15 6.87-3.17a7.34 7.34 0 01-.09 1.32z"
                id="prefix__kant"
              />
              <path
                d="M187.78 96.49c-.45 4-3.79 7.27-7.46 7.34A698.11 698.11 0 0117.84 85.36c-3.56-.9-6.07-4.87-5.62-8.83a6.7 6.7 0 01.26-1.29c.16 3.44 2.81 3.94 6 4.63A923.55 923.55 0 00181 98.34c3.25 0 5.94.15 6.87-3.17a7.34 7.34 0 01-.09 1.32z"
                style={{
                  mixBlendMode: 'multiply',
                }}
                fill="#ecc577"
              />
              <path
                className="prefix__cls-11"
                d="M187.78 96.49c-.45 4-3.79 7.27-7.46 7.34A698.11 698.11 0 0117.84 85.36c-3.56-.9-6.07-4.87-5.62-8.83a6.7 6.7 0 01.26-1.29c.16 3.44 2.81 3.94 6 4.63A923.55 923.55 0 00181 98.34c3.25 0 5.94.15 6.87-3.17a7.34 7.34 0 01-.09 1.32z"
                id="prefix__kant-2"
                data-name="kant"
                opacity={0.2}
              />
              <path
                className="prefix__cls-10"
                d="M144.05 68.16l-1 9.08s-12.56-.41-41.87-3.75c-27-3.06-41-5.67-41-5.67l1-9.08c2.84-24.94 23.56-40.6 46.28-38 22.9 2.58 39.47 22.05 36.59 47.42z"
              />
              <g clipPath="url(#prefix__clip-path)">
                <path
                  className="prefix__cls-15"
                  d="M144.62 64.26s-11.53 1.74-42.23-1.78-40.75-7.65-40.75-7.65M107.48 20.72l-5.09 41.76M133.12 64.78c6.42-53.67-54.43-61.49-60.95-6.93M143.24 76.36s-11.52 1.71-42.24-1.78-40.75-7.65-40.75-7.65"
                />
              </g>
              <ellipse
                className="prefix__cls-16"
                cx={136.56}
                cy={71.13}
                rx={3.46}
                ry={2.14}
                transform="rotate(-83.51 136.568 71.127)"
              />
              <ellipse
                className="prefix__cls-16"
                cx={64.98}
                cy={62.54}
                rx={3.46}
                ry={2.14}
                transform="rotate(-83.51 64.98 62.535)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EbotiIntro;
